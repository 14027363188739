import React from "react";

import {
    InfoContainer,
    InfoWrapper,
    InfoRow,
    Column1,
    Column2,
    TextWrapper,
    Heading,
    TextBlock,
    Subtitle,
} from "./InfoElements";

const InstructorInfo = ({ lightBg, lightText, darkText }) => {
    return (
        <InfoContainer lightBg={false} id={"instructors"}>
            <InfoWrapper>
                <InfoRow>
                    <Column1>
                        <Heading lightText={true}>{"Instructors"}</Heading>
                        <TextWrapper>
                            <Subtitle lightText={true}>
                                {"Head Instructor: Daisuke Hayashi"}
                            </Subtitle>
                            <TextBlock darkText={false}>
                                Hayashi sensei, 7th dan kyoshi, is a highly qualified
                                kendo instructor. He has coached at Osaka Prefecture
                                University, Osaka, and Nagoya City University, Aichi,
                                Japan, and has special training as a kendo coach.
                                <br /> <br />
                                He has won the Tokai Area University Kendo Championship
                                twice. He has also published academic papers on the sports
                                physiology of kendo, and holds a M. P.E. from Osaka
                                University in Health and Sports Science. In addition to
                                kendo, Hayashi sensei also holds 3rd dan in Iaido.
                                <br /> <br />
                                Hayashi sensei was the 2015 Team Canada Ladies Coach and
                                was also the 2012 Team Canada Ladies Coach. We are very
                                lucky to have such a highly qualified and skilled
                                instructor in Victoria. At times, instruction may be given
                                by other highly experienced members of the Kendo Club as
                                directed by Hayashi sensei.
                            </TextBlock>
                        </TextWrapper>
                    </Column1>
                    <Column2>
                        <TextWrapper>
                            <center>
                                <Subtitle lightText={true}>
                                    {"Assistant Instructors"}
                                </Subtitle>

                                <TextBlock darkText={false}>
                                    Shigeru Adachi, 6th dan
                                    <br /> <br />
                                    Jason Walton, 5th dan
                                    <br /> <br />
                                    Minako Gardner, 5th dan
                                    <br /> <br />
                                    Eric Gowland, 4th dan
                                    <br /> <br />
                                    Kevin Buckham, 3rd dan
                                </TextBlock>
                            </center>
                        </TextWrapper>
                    </Column2>
                </InfoRow>
            </InfoWrapper>
        </InfoContainer>
    );
};

export default InstructorInfo;
